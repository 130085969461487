import { Route, Routes } from 'react-router-dom'
import { AuthNavigate } from "../components/AuthNavigate/AuthNavigate"
import { NotFound } from "../layouts/NotFound/NotFound"
import { CIS011 } from '../layouts/cargo/CIS011/CIS011'
import { OCS010 } from "../layouts/order/OCS010/OCS010"
import { OCS011 } from "../layouts/order/OCS011/OCS011"
import { OCS012 } from "../layouts/order/OCS012/OCS012"
import { OCS021 } from "../layouts/order/OCS021/OCS021"
import { OCS022 } from "../layouts/order/OCS022/OCS022"
import { OCS023 } from "../layouts/order/OCS023/OCS023"
import { OCS024 } from "../layouts/order/OCS024/OCS024"
import { OCS026 } from "../layouts/order/OCS026/OCS026"
import { OCS027 } from "../layouts/order/OCS027/OCS027"
import { OCS028 } from "../layouts/order/OCS028/OCS028"
import { OCS029 } from "../layouts/order/OCS029/OCS029"
import { OCS030 } from "../layouts/order/OCS030/OCS030"
import { OCS031 } from "../layouts/order/OCS031/OCS031"
import { OFS010 } from "../layouts/order/OFS010/OFS010"
import { ONS010 } from "../layouts/order/ONS010/ONS010"
import { ONS011 } from "../layouts/order/ONS011/ONS011"
import { ONS030 } from "../layouts/order/ONS030/ONS030"
import { ONS031 } from "../layouts/order/ONS031/ONS031"
import { ONS040 } from '../layouts/order/ONS040/ONS040'
import { ONS041 } from '../layouts/order/ONS041/ONS041'
import { OPS010 } from "../layouts/order/OPS010/OPS010"
import { OPS011 } from "../layouts/order/OPS011/OPS011"
import { OPS012 } from "../layouts/order/OPS012/OPS012"
import { OPS020 } from "../layouts/order/OPS020/OPS020"
import { OPS021 } from "../layouts/order/OPS021/OPS021"
import { ORS010 } from "../layouts/order/ORS010/ORS010"
import { ORS011 } from "../layouts/order/ORS011/ORS011"
import { ORS020 } from '../layouts/order/ORS020/ORS020'
import { ORS021 } from '../layouts/order/ORS021/ORS021'
import { OSS010 } from "../layouts/order/OSS010/OSS010"
import { OSS011 } from "../layouts/order/OSS011/OSS011"
import { OSS012 } from "../layouts/order/OSS012/OSS012"
import { OSS020 } from "../layouts/order/OSS020/OSS020"
import { OSS021 } from "../layouts/order/OSS021/OSS021"

export function OrderRoutes() {
    return <Routes>
        <Route path="/placecustorder-sowc/create" element={<AuthNavigate access="ORDER.OCS021.VIEW"><OCS021 /></AuthNavigate>} />
        <Route path="/placecustorder-sowc/edit-:orderId" element={<AuthNavigate access="ORDER.OCS021.VIEW"><OCS021 /></AuthNavigate>} />
        <Route path="/placecustorder-sowc/view-:orderId" element={<AuthNavigate access="ORDER.OCS021.VIEW"><OCS021 /></AuthNavigate>} />

        <Route path="/monitoring/view-:coSpotId" element={<AuthNavigate access="ORDER.OCS031.VIEW"><OCS031 /></AuthNavigate>} />
        <Route path="/monitoring" element={<AuthNavigate access="ORDER.OCS030.VIEW"><OCS030 /></AuthNavigate>} />

        <Route path="/co/fc-:orderId" element={<AuthNavigate access="ORDER.OCS012.VIEW"><OCS012 /></AuthNavigate>} />
        <Route path="/co/detail-:orderId" element={<AuthNavigate access="ORDER.OCS011.VIEW"><OCS011 /></AuthNavigate>} />
        <Route path="/co" element={<AuthNavigate access="ORDER.OCS010.VIEW"><OCS010 /></AuthNavigate>} />

        <Route path="/cfc" element={<AuthNavigate access="ORDER.OFS010.VIEW"><OFS010 /></AuthNavigate>} />

        <Route path="/placecustorder-spot/create-:contractRouteId" element={<AuthNavigate access="ORDER.ONS011.VIEW"><ONS011 /></AuthNavigate>} />
        <Route path="/placecustorder-spot/view-:contractRouteId" element={<AuthNavigate access="ORDER.ONS011.VIEW"><ONS011 /></AuthNavigate>} />
        <Route path="/placecustorder-regular/create-:contractRouteId" element={<AuthNavigate access="ORDER.ONS011.VIEW"><ONS011 /></AuthNavigate>} />
        <Route path="/placecustorder-regular/view-:contractRouteId" element={<AuthNavigate access="ORDER.ONS011.VIEW"><ONS011 /></AuthNavigate>} />
        <Route path="/placecustorder" element={<AuthNavigate access="ORDER.ONS010.VIEW"><ONS010 /></AuthNavigate>} />
        <Route path="/placecustorder-regular" element={<AuthNavigate access="ORDER.ONS010REGULAR.VIEW"><ONS010 /></AuthNavigate>} />
        <Route path="/placecustorder-spot" element={<AuthNavigate access="ORDER.ONS010SPOT.VIEW"><ONS010 /></AuthNavigate>} />
        <Route path="/placecustorder-sowc" element={<AuthNavigate access="ORDER.ONS010SOWC.VIEW"><ONS010 /></AuthNavigate>} />

        <Route path="/cro-order/create-:coId" element={<AuthNavigate access="ORDER.ONS031.VIEW"><ONS031 /></AuthNavigate>} />
        <Route path="/cro-order/edit-:roId" element={<AuthNavigate access="ORDER.ONS031.VIEW"><ONS031 /></AuthNavigate>} />
        <Route path="/cro-order/view-:roId" element={<AuthNavigate access="ORDER.ONS031.VIEW"><ONS031 /></AuthNavigate>} />
        <Route path="/cro-forecast/create-:coId" element={<AuthNavigate access="ORDER.ONS031.VIEW"><ONS031 /></AuthNavigate>} />
        <Route path="/cro-forecast/edit-:roId" element={<AuthNavigate access="ORDER.ONS031.VIEW"><ONS031 /></AuthNavigate>} />
        <Route path="/cro-forecast/view-:roId" element={<AuthNavigate access="ORDER.ONS031.VIEW"><ONS031 /></AuthNavigate>} />
        <Route path="/cro" element={<AuthNavigate access="ORDER.ONS030.VIEW"><ONS030 /></AuthNavigate>} />
        <Route path="/cro-order" element={<AuthNavigate access="ORDER.ONS030ORDER.VIEW"><ONS030 /></AuthNavigate>} />
        <Route path="/cro-forecast" element={<AuthNavigate access="ORDER.ONS030FORECAST.VIEW"><ONS030 /></AuthNavigate>} />

        <Route path="/bro-order/create-:poId" element={<AuthNavigate access="ORDER.ONS041.VIEW"><ONS041 /></AuthNavigate>} />
        <Route path="/bro-order/edit-:roId" element={<AuthNavigate access="ORDER.ONS041.VIEW"><ONS041 /></AuthNavigate>} />
        <Route path="/bro-order/view-:roId" element={<AuthNavigate access="ORDER.ONS041.VIEW"><ONS041 /></AuthNavigate>} />
        <Route path="/bro-forecast/create-:poId" element={<AuthNavigate access="ORDER.ONS041.VIEW"><ONS041 /></AuthNavigate>} />
        <Route path="/bro-forecast/edit-:roId" element={<AuthNavigate access="ORDER.ONS041.VIEW"><ONS041 /></AuthNavigate>} />
        <Route path="/bro-forecast/view-:roId" element={<AuthNavigate access="ORDER.ONS041.VIEW"><ONS041 /></AuthNavigate>} />
        <Route path="/bro" element={<AuthNavigate access="ORDER.ONS040.VIEW"><ONS040 /></AuthNavigate>} />
        <Route path="/bro-order" element={<AuthNavigate access="ORDER.ONS040ORDER.VIEW"><ONS040 /></AuthNavigate>} />
        <Route path="/bro-forecast" element={<AuthNavigate access="ORDER.ONS040FORECAST.VIEW"><ONS040 /></AuthNavigate>} />

        <Route path="/po/fc-:orderId" element={<AuthNavigate access="ORDER.OPS012.VIEW"><OPS012 /></AuthNavigate>} />
        <Route path="/po/detail-:orderId" element={<AuthNavigate access="ORDER.OPS011.VIEW"><OPS011 /></AuthNavigate>} />
        <Route path="/po/cargotrackingDetail-:orderNo" element={<AuthNavigate access="LOGI.CIS011.VIEW"><CIS011 /></AuthNavigate>} />
        <Route path="/so/cargotrackingDetail-:orderNo" element={<AuthNavigate access="LOGI.CIS011.VIEW"><CIS011 /></AuthNavigate>} />
        <Route path="/co/cargotrackingDetail-:orderNo" element={<AuthNavigate access="LOGI.CIS011.VIEW"><CIS011 /></AuthNavigate>} />
        <Route path="/po" element={<AuthNavigate access="ORDER.OPS010.VIEW"><OPS010 /></AuthNavigate>} />

        <Route path="/pospot/view-:orderId" element={<AuthNavigate access="ORDER.OPS021.VIEW"><OPS021 /></AuthNavigate>} />
        <Route path="/pospot" element={<AuthNavigate access="ORDER.OPS020.VIEW"><OPS020 /></AuthNavigate>} />

        <Route path="/cc/-:roSoId-:dataFlag-:soId-:requestType" element={<AuthNavigate access="ORDER.ORS011.VIEW"><ORS011 /></AuthNavigate>} />
        <Route path="/cc" element={<AuthNavigate access="ORDER.ORS010.VIEW"><ORS010 /></AuthNavigate>} />

        <Route path="/so/suppRoView-:suppRoId" element={<AuthNavigate access="ORDER.ORS021.VIEW"><ORS021 /></AuthNavigate>} />
        <Route path="/so/suppRoEdit-:suppRoId" element={<AuthNavigate access="ORDER.ORS021.VIEW"><ORS021 /></AuthNavigate>} />
        <Route path="/so/suppRoCreate-:soId" element={<AuthNavigate access="ORDER.ORS021.VIEW"><ORS021 /></AuthNavigate>} />
        <Route path="/so/suppRoViewpna-:suppRoId" element={<AuthNavigate access="ORDER.ORS021.VIEW"><ORS021 /></AuthNavigate>} />
        <Route path="/so/suppRoEditpna-:suppRoId" element={<AuthNavigate access="ORDER.ORS021.VIEW"><ORS021 /></AuthNavigate>} />
        <Route path="/so/suppRoCreatepna-:soId" element={<AuthNavigate access="ORDER.ORS021.VIEW"><ORS021 /></AuthNavigate>} />
        <Route path="/so/fc-:orderId" element={<AuthNavigate access="ORDER.OSS012.VIEW"><OSS012 /></AuthNavigate>} />
        <Route path="/so/detail-:orderId" element={<AuthNavigate access="ORDER.OSS011.VIEW"><OSS011 /></AuthNavigate>} />
        <Route path="/so" element={<AuthNavigate access="ORDER.OSS010.VIEW"><OSS010 /></AuthNavigate>} />

        <Route path="/soMonitoring/detail-:soSpotId" element={<AuthNavigate access="ORDER.OSS021.VIEW"><OSS021 /></AuthNavigate>} />
        <Route path="/soMonitoring" element={<AuthNavigate access="ORDER.OSS020.VIEW"><OSS020 /></AuthNavigate>} />


        <Route path="/rece/modify-:soSpotId" element={<AuthNavigate access="ORDER.OCS023.VIEW"><OCS023 /></AuthNavigate>} />
        <Route path="/rece" element={<AuthNavigate access="ORDER.OCS022.VIEW"><OCS022 /></AuthNavigate>} />
        <Route path="/placebuorder/sowcDetailEdit-:orderId" element={<AuthNavigate access="ORDER.OCS024.VIEW"><OCS024 /></AuthNavigate>} />
        <Route path="/placebuorder/sowcDetailView-:orderId" element={<AuthNavigate access="ORDER.OCS024.VIEW"><OCS024 /></AuthNavigate>} />
        <Route path="/placebuorder" element={<AuthNavigate access="ORDER.OCS022.VIEW"><OCS022 /></AuthNavigate>} />

        <Route path="/pcwc/modify-:coSpotId" element={<AuthNavigate access="ORDER.OCS026.VIEW"><OCS026 /></AuthNavigate>} />
        <Route path="/pcwc" element={<AuthNavigate access="ORDER.ONS030.VIEW"><ONS030 /></AuthNavigate>} />
        <Route path="/repcwc" element={<AuthNavigate access="ORDER.OCS027.VIEW"><OCS027 /></AuthNavigate>} />
        <Route path="/rc/edit-:roSpotId" element={<AuthNavigate access="ORDER.OCS028.VIEW"><OCS028 /></AuthNavigate>} />
        <Route path="/rc/modify-:roSpotId" element={<AuthNavigate access="ORDER.OCS029.VIEW"><OCS029 /></AuthNavigate>} />
        <Route path="/rc" element={<AuthNavigate access="ORDER.OCS027.VIEW"><OCS027 /></AuthNavigate>} />
        <Route path="*" element={<NotFound scope="order" />} />

        <Route path="/scro" element={<AuthNavigate access="ORDER.ORS020.VIEW"><ORS020 /></AuthNavigate>} />
        <Route path="/scro/create-:soId" element={<AuthNavigate access="ORDER.ORS021.VIEW"><ORS021 /></AuthNavigate>} />
        <Route path="/scro/view-:suppRoId" element={<AuthNavigate access="ORDER.ORS021.VIEW"><ORS021 /></AuthNavigate>} />
        <Route path="/scro/edit-:suppRoId" element={<AuthNavigate access="ORDER.ORS021.VIEW"><ORS021 /></AuthNavigate>} />

        <Route path="/scro/createpna-:soId" element={<AuthNavigate access="ORDER.ORS021.VIEW"><ORS021 /></AuthNavigate>} />
        <Route path="/scro/viewpna-:suppRoId" element={<AuthNavigate access="ORDER.ORS021.VIEW"><ORS021 /></AuthNavigate>} />
        <Route path="/scro/editpna-:suppRoId" element={<AuthNavigate access="ORDER.ORS021.VIEW"><ORS021 /></AuthNavigate>} />
    </Routes>
}