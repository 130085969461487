/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { ConfirmInboundInfo, confirmInboundInfoToJson } from "../models/ConfirmInboundInfo"
import { DownloadInboundFormResult, downloadInboundFormResultFromJson } from "../models/DownloadInboundFormResult"
import { InboundInfo, inboundInfoFromJson } from "../models/InboundInfo"
import { InboundMonitorInfo, inboundMonitorInfoFromJson } from "../models/InboundMonitorInfo"
import { InboundMonitorTransfer, inboundMonitorTransferFromJson } from "../models/InboundMonitorTransfer"
import { InboundMonitorView, inboundMonitorViewToJson } from "../models/InboundMonitorView"
import { InboundResult, inboundResultToJson } from "../models/InboundResult"
import { SwitchInboundInfo, switchInboundInfoToJson } from "../models/SwitchInboundInfo"

export const useGetInboundPage = () => {
    const url = '/lcbm-logistics-api/api/getInboundPageByVO'
    const payloadProject = useCallback(inboundMonitorViewToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(inboundMonitorTransferFromJson), [])
    return usePost<InboundMonitorView, PageResultEntity<InboundMonitorTransfer>>(url, payloadProject, resultProject)
}

export const useGetInboundInfo = () => {
    const url = '/lcbm-logistics-api/api/getInboundDetailInfo'
    const payloadProject = useCallback((obj: { inboundNo: string }) => obj.inboundNo, [])
    const resultProject = useCallback(inboundMonitorInfoFromJson, [])
    return usePost<{ inboundNo: string }, InboundMonitorInfo>(url, payloadProject, resultProject)
}

export const useGetIncomingOrProcessInbound = () => {
    const url = '/lcbm-logistics-api/api/getIncomingOrProcessInbound'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(inboundInfoFromJson), [])
    return usePost<undefined, InboundInfo[]>(url, payloadProject, resultProject)
}

export const useConfirmInboundResult = () => {
    const url = '/lcbm-logistics-api/api/confirmInboundResult'
    const payloadProject = useCallback(Projects.array(inboundResultToJson), [])
    const resultProject = Projects.IGNORE
    return usePost<InboundResult[], undefined>(url, payloadProject, resultProject)
}

export const useTotallyConfirmInbound = () => {
    const url = '/lcbm-logistics-api/api/totallyConfirmInbound'
    const payloadProject = useCallback(confirmInboundInfoToJson, [])
    const resultProject = Projects.IGNORE
    return usePost<ConfirmInboundInfo, undefined>(url, payloadProject, resultProject)
}

export const useSwitchInbound = () => {
    const url = '/lcbm-logistics-api/api/inbound/switch'
    const payloadProject = useCallback(switchInboundInfoToJson, [])
    const resultProject = Projects.IGNORE
    return usePost<SwitchInboundInfo, undefined>(url, payloadProject, resultProject)
}

export const useUndoInbound = () => {
    const url = '/lcbm-logistics-api/api/inbound/undo'
    const payloadProject = useCallback((obj: { inboundNo: string }) => obj, [])
    const resultProject = Projects.IGNORE
    return usePost<{ inboundNo: string }, undefined>(url, payloadProject, resultProject)
}

export const useGetInboundPageForRoots = () => {
    const url = '/lcbm-logistics-api/api/getInboundPageByVOForRoots'
    const payloadProject = useCallback(inboundMonitorViewToJson, []);
    const resultProject = useCallback(pageResultEntityFromJson(inboundMonitorTransferFromJson), []);
    return usePost<InboundMonitorView, PageResultEntity<InboundMonitorTransfer>>(url, payloadProject, resultProject);
}

export const useFindManualInboundParts = () => {
    const url = '/lcbm-logistics-api/api/findManualInboundParts';
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(downloadInboundFormResultFromJson), []);
    return usePost<undefined, DownloadInboundFormResult[]>(url, payloadProject, resultProject);
}